import { computed, defineComponent, toRefs } from 'vue';
import classNames from '../_util/classNames';
import { isFunction } from '../_util/util';
import CloseOutlined from '@ant-design/icons-vue/CloseOutlined';
import { tourStepProps } from './interface';
import LocaleReceiver from '../locale/LocaleReceiver';
import Button from '../button';
import defaultLocale from '../locale/en_US';
const panelRender = defineComponent({
    name: 'ATourPanel',
    inheritAttrs: false,
    props: tourStepProps(),
    setup(props, { attrs, slots }) {
        const { current, total } = toRefs(props);
        const isLastStep = computed(() => current.value === total.value - 1);
        const prevBtnClick = e => {
            var _a;
            const prevButtonProps = props.prevButtonProps;
            (_a = props.onPrev) === null || _a === void 0 ? void 0 : _a.call(props, e);
            if (typeof (prevButtonProps === null || prevButtonProps === void 0 ? void 0 : prevButtonProps.onClick) === 'function') {
                prevButtonProps === null || prevButtonProps === void 0 ? void 0 : prevButtonProps.onClick();
            }
        };
        const nextBtnClick = e => {
            var _a, _b;
            const nextButtonProps = props.nextButtonProps;
            if (isLastStep.value) {
                (_a = props.onFinish) === null || _a === void 0 ? void 0 : _a.call(props, e);
            }
            else {
                (_b = props.onNext) === null || _b === void 0 ? void 0 : _b.call(props, e);
            }
            if (typeof (nextButtonProps === null || nextButtonProps === void 0 ? void 0 : nextButtonProps.onClick) === 'function') {
                nextButtonProps === null || nextButtonProps === void 0 ? void 0 : nextButtonProps.onClick();
            }
        };
        return () => {
            const { prefixCls, title, onClose, cover, description, type: stepType, arrow } = props;
            const prevButtonProps = props.prevButtonProps;
            const nextButtonProps = props.nextButtonProps;
            let headerNode;
            if (title) {
                headerNode = (<div class={`${prefixCls}-header`}>
            <div class={`${prefixCls}-title`}>{title}</div>
          </div>);
            }
            let descriptionNode;
            if (description) {
                descriptionNode = <div class={`${prefixCls}-description`}>{description}</div>;
            }
            let coverNode;
            if (cover) {
                coverNode = <div class={`${prefixCls}-cover`}>{cover}</div>;
            }
            let mergeIndicatorNode;
            if (slots.indicatorsRender) {
                mergeIndicatorNode = slots.indicatorsRender({ current: current.value, total });
            }
            else {
                mergeIndicatorNode = [...Array.from({ length: total.value }).keys()].map((stepItem, index) => (<span key={stepItem} class={classNames(index === current.value && `${prefixCls}-indicator-active`, `${prefixCls}-indicator`)}/>));
            }
            const mainBtnType = stepType === 'primary' ? 'default' : 'primary';
            const secondaryBtnProps = {
                type: 'default',
                ghost: stepType === 'primary',
            };
            return (<LocaleReceiver componentName="Tour" defaultLocale={defaultLocale.Tour}>
          {contextLocale => {
                    var _a;
                    return (<div {...attrs} class={classNames(stepType === 'primary' ? `${prefixCls}-primary` : '', attrs.class, `${prefixCls}-content`)}>
              {arrow && <div class={`${prefixCls}-arrow`} key="arrow"/>}
              <div class={`${prefixCls}-inner`}>
                <CloseOutlined class={`${prefixCls}-close`} onClick={onClose}/>
                {coverNode}
                {headerNode}
                {descriptionNode}
                <div class={`${prefixCls}-footer`}>
                  {total.value > 1 && (<div class={`${prefixCls}-indicators`}>{mergeIndicatorNode}</div>)}
                  <div class={`${prefixCls}-buttons`}>
                    {current.value !== 0 ? (<Button {...secondaryBtnProps} {...prevButtonProps} onClick={prevBtnClick} size="small" class={classNames(`${prefixCls}-prev-btn`, prevButtonProps === null || prevButtonProps === void 0 ? void 0 : prevButtonProps.className)}>
                        {isFunction(prevButtonProps === null || prevButtonProps === void 0 ? void 0 : prevButtonProps.children)
                                ? prevButtonProps.children()
                                : (_a = prevButtonProps === null || prevButtonProps === void 0 ? void 0 : prevButtonProps.children) !== null && _a !== void 0 ? _a : contextLocale.Previous}
                      </Button>) : null}
                    <Button type={mainBtnType} {...nextButtonProps} onClick={nextBtnClick} size="small" class={classNames(`${prefixCls}-next-btn`, nextButtonProps === null || nextButtonProps === void 0 ? void 0 : nextButtonProps.className)}>
                      {isFunction(nextButtonProps === null || nextButtonProps === void 0 ? void 0 : nextButtonProps.children)
                            ? nextButtonProps === null || nextButtonProps === void 0 ? void 0 : nextButtonProps.children()
                            : isLastStep.value
                                ? contextLocale.Finish
                                : contextLocale.Next}
                    </Button>
                  </div>
                </div>
              </div>
            </div>);
                }}
        </LocaleReceiver>);
        };
    },
});
export default panelRender;
